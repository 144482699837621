// Submitted_Applications.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Applications.css';

const Submitted_Applications = () => {
  const [bioData, setBioData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [sponsorData, setSponsorData] = useState([]);
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [submittedApplications, setSubmittedApplications] = useState([]);
  const [expandedIndices, setExpandedIndices] = useState([]);
  const [decisions, setDecisions] = useState({});
  const [errorMessages, setErrorMessages] = useState("");
  const [successMessages, setSuccessMessages] = useState({}); 
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchSubmittedApplicationsData = async () => {
      try {
        const email = location.state?.email || localStorage.getItem('email');

        if (email) {
          localStorage.setItem('email', email);
        } else {
          setError('Email is undefined');
          setLoading(false);
          return;
        }

        // Fetch user bio data
        const bioResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`
        );
        if (!bioResponse.ok) {
          const errorBody = await bioResponse.text();
          throw new Error(`Failed to fetch bio data: ${errorBody}`);
        }
        const bioData = await bioResponse.json();
        setBioData(bioData);
        setUserRole(bioData.userRole);

        // Fetch sponsor data
        const sponsorResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/sponsors`
        );
        if (!sponsorResponse.ok) {
          const errorBody = await sponsorResponse.text();
          throw new Error(`Failed to fetch sponsor data: ${errorBody}`);
        }
        const sponsorData = await sponsorResponse.json();
        setSponsorData(sponsorData);

        // Fetch submitted applications based on user role
        let submittedResponse;
        if (bioData.userRole === 'Driver') {
          submittedResponse = await fetch(
            `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/submittedApplications?applicantEmail=${encodeURIComponent(email)}`
          );
          setSelectedSponsor({ name: bioData.sponsorOrg });
        } else if (bioData.userRole === 'Sponsor') {
          submittedResponse = await fetch(
            `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/submittedApplications?sponsorOrgName=${encodeURIComponent(bioData.sponsorOrg)}`
          );
          setSelectedSponsor({ name: bioData.sponsorOrg });
        } else if (bioData.userRole === 'Admin') {
          submittedResponse = await fetch(
            `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/submittedApplications`
          );
        }

        if (!submittedResponse.ok) {
          const errorBody = await submittedResponse.text();
          throw new Error(`Failed to fetch submitted applications: ${errorBody}`);
        }

        const submittedApplicationsData = await submittedResponse.json();
        
        // Sort the applications by dateSubmitted (descending order)
        const sortedApplications = submittedApplicationsData.sort((a, b) => {
          const dateA = new Date(a.dateSubmitted);
          const dateB = new Date(b.dateSubmitted);
          return dateA - dateB;
        });

        setSubmittedApplications(sortedApplications);

        // Initialize the decision state to "Denied" for all applications
        const initialDecisions = {};
        submittedApplicationsData.forEach(application => {
          initialDecisions[application.applicationID] = {
            decision: 'Denied', // Default decision
            explanation: ''
          };
        });
        setDecisions(initialDecisions);
        
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchSubmittedApplicationsData();
  }, [location.state]);

  const handleSortChange = (event) => {
    const newSortOrder = event.target.value;
    setSortOrder(newSortOrder);
  
    const sortedApplications = [...submittedApplications].sort((a, b) => {
      const dateA = new Date(a.dateSubmitted);
      const dateB = new Date(b.dateSubmitted);
      return newSortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
  
    setSubmittedApplications(sortedApplications);
  };

  const handleSponsorSelect = async (event) => {
    const sponsorID = parseInt(event.target.value);
    
    if (sponsorID === 0) {
      setSelectedSponsor(null);
      setSubmittedApplications([]); // Clear the applications when no sponsor is selected
      return;
    }
  
    const selectedSponsorData = sponsorData.find(
      (sponsor) => sponsor.sponsorOrgID === sponsorID
    );
    
    if (!selectedSponsorData) {
      setSelectedSponsor(null);
      setSubmittedApplications([]); // Clear applications if no valid sponsor is found
      return;
    }
    
    setSelectedSponsor({ id: selectedSponsorData.sponsorOrgID, name: selectedSponsorData.sponsorOrgName });
  
    // Fetch submitted applications filtered by sponsor organization
    try {
      setLoading(true);
      const email = location.state?.email || localStorage.getItem('email');
    
      if (!email) {
        setError('Email is undefined');
        setLoading(false);
        return;
      }
    
      const submittedResponse = await fetch(
        `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/submittedApplications?sponsorOrgName=${encodeURIComponent(selectedSponsorData.sponsorOrgName)}`
      );
      if (!submittedResponse.ok) {
        const errorBody = await submittedResponse.text();
        throw new Error(`Failed to fetch submitted applications: ${errorBody}`);
      }
    
      const submittedApplicationsData = await submittedResponse.json();
      setSubmittedApplications(submittedApplicationsData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  }; 

  const toggleExpand = (index) => {
    setExpandedIndices(prev => 
      prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
    );
  };

  const handleDecision = (applicationID, decision) => {
    setDecisions(prev => ({
      ...prev,
      [applicationID]: {
        ...prev[applicationID],
        decision,
      },
    }));
    console.log(`Application ${applicationID} marked as ${decision}`);
  };
  
  const handleExplanationChange = (applicationID, explanation) => {
    setDecisions(prev => ({
      ...prev,
      [applicationID]: {
        ...prev[applicationID],
        explanation,
      },
    }));
  };

  const handleSubmit = async (applicationID, index) => {
    const decision = decisions[applicationID]?.decision;
    const explanation = decisions[applicationID]?.explanation;
  
    if (decision === 'Denied' && !explanation.trim()) {
      setErrorMessages(prevMessages => ({
        ...prevMessages,
        [applicationID]: "You must provide an explanation for why this application was rejected.",
      }));
      return;
    }
  
    // Prepare the request body
    const requestBody = {
      applicationID: applicationID,
      status: decision.toLowerCase(),
    };
  
    // If the decision is 'Denied' and an explanation exists, include it
    if (decision === 'Denied' && explanation) {
      requestBody.decisionExplanation = explanation;
    }
  
    try {
      const response = await fetch(
        `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/submittedApplications`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        }
      );
  
      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(`Failed to update application: ${errorBody}`);
      }

      // Set success message for the specific application
      setSuccessMessages(prevMessages => ({
        ...prevMessages,
        [applicationID]: "Decision submitted successfully",
      }));

      setExpandedIndices(prev => 
        prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
      );
  
      setSubmittedApplications((prevApplications) =>
        prevApplications.map((app) =>
          app.applicationID === applicationID
            ? { ...app, status: decision }
            : app
        )
      );
  
    } catch (error) {
      console.error("Error submitting decision:", error);
    }
  };
  
    
  const gotoApplications = () => {
    navigate('/Applications');
  };

  if (loading) {
    return <p className="loading">Loading...</p>;
  }

  return (
    <div className="submitted-applications-container">
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          <button onClick={gotoApplications} className="applications-button">
            Back to Applications
          </button>

          <div className="sort-order">
            <label htmlFor="sortOrder" className='sort-label'>Sort by:</label>
            <select 
              id="sortOrder" 
              value={sortOrder} 
              onChange={handleSortChange}
              className='sort-dropdown'
            >
              <option value="asc">Least Recent</option>
              <option value="desc">Most Recent</option>
            </select>
          </div>

          <h1>Submitted Applications</h1>
          <div className='submitted-sponsor-org-header'>
            {userRole === 'Sponsor' && bioData && (
              <h2>Sponsor Organization: {bioData.sponsorOrg}</h2>
            )}
          </div>

          {userRole === 'Admin' && (
            <div className="change-sponsor">
              <div className="form-group">
                <label htmlFor="sponsorSelect">Select a sponsor:</label>
                <select
                  id="sponsorSelect"
                  value={selectedSponsor?.id || ''}
                  onChange={handleSponsorSelect}
                  className="form-control"
                >
                  <option value="">-- Select Sponsor --</option>
                  {sponsorData.map((sponsor) => (
                    <option key={sponsor.sponsorOrgID} value={sponsor.sponsorOrgID}>
                      {sponsor.sponsorOrgName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {selectedSponsor === null ? (
            <p>Please select a sponsor to see submitted applications.</p>
          ) : submittedApplications.length > 0 ? (
            submittedApplications
              .filter((application) => !selectedSponsor || application.sponsorOrgName === selectedSponsor.name)
              .map((application, index) => (
              <div className="application-card" key={index}>
                {userRole === 'Driver' ? (
                  <>
                    <h2>Application {index + 1}</h2>
                    <p><strong>Sponsor Organization:</strong> {application.sponsorOrgName}</p>
                    <p><strong>Applicant Name:</strong> {application.applicantFirstName} {application.applicantLastName}</p>
                    <p><strong>Email:</strong> {application.applicantEmail}</p>
                    <p><strong>Date Submitted:</strong> {application.dateSubmitted}</p>
                    <p>
                      <strong>Status: </strong> 
                      <span className={`application-status ${application.status.toLowerCase()}`}>
                        {application.status}
                      </span>
                    </p>
                    {application.decisionExplanation && (
                      <p><strong>Explanation:</strong> "{application.decisionExplanation}"</p>
                    )}
                    <div className="application-questions">
                      <h3>Responses:</h3>
                      {Object.entries(application.questions).map(([key, question]) => {
                        if (question.text || question.answer) {
                          return (
                            <p key={key}>
                              <strong>{question.text || 'Untitled question'}:</strong> {question.answer || 'No answer provided'}
                            </p>
                          );
                        }
                        return null; 
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <h2>Application {index + 1}</h2>
                    <p><strong>Applicant Name:</strong> {application.applicantFirstName} {application.applicantLastName}</p>
                    <p><strong>Email:</strong> {application.applicantEmail}</p>
                    <p><strong>Date Submitted:</strong> {application.dateSubmitted}</p>
                    <p>
                      <strong>Status: </strong> 
                      <span className={`application-status ${application.status.toLowerCase()}`}>
                        {application.status}
                      </span>
                    </p>
                    {expandedIndices.includes(index) ? (
                      <>
                        <div className="application-questions">
                          <h3>Responses:</h3>
                          {Object.entries(application.questions).map(([key, question]) => {
                            if (question.text || question.answer) {
                              return (
                                <p key={key}>
                                  <strong>{question.text || 'Untitled question'}:</strong> {question.answer || 'No answer provided'}
                                </p>
                              );
                            }
                            return null; 
                          })}
                        </div>
                        <div className="divider"></div>
                        <div className="application-decision">
                          <label className="switch">
                            <input 
                              type="checkbox" 
                              checked={decisions[application.applicationID]?.decision === 'Accepted'} 
                              onChange={() => handleDecision(application.applicationID, decisions[application.applicationID]?.decision === 'Accepted' ? 'Denied' : 'Accepted')}
                            />
                            <span className="slider"></span>
                            <span className="switch-text">
                              {decisions[application.applicationID]?.decision === 'Accepted' ? 'Accept' : 'Deny'}
                            </span>
                          </label>
                          <div className="explanation-input">
                            <textarea
                              placeholder="Provide an explanation for your decision. (Required for denial)"
                              value={decisions[application.applicationID]?.explanation || ''}
                              onChange={(e) => handleExplanationChange(application.applicationID, e.target.value)}
                            />
                          </div>
                          {decisions[application.applicationID]?.decision === 'Denied' && errorMessages[application.applicationID] && (
                            <div className="error-message">
                              {errorMessages[application.applicationID]}
                            </div>
                          )}
                          <button onClick={() => handleSubmit(application.applicationID, index)} className="submit-decision-button">
                            Submit Decision
                          </button>
                        </div>
                        <div className="divider"></div>
                        <button className='collapse-button' onClick={() => toggleExpand(index)}>Collapse</button>
                      </>
                    ) : (
                      <>
                        {successMessages[application.applicationID] && (
                          <div className="success-message">
                            {successMessages[application.applicationID]}
                          </div>
                        )}
                        <button className='expand-button' onClick={() => toggleExpand(index)}>Expand</button>
                      </>
                    )}
                  </>
                )}
              </div>
            ))
          ) : (
            <p>No submitted applications found.</p>
          )}
        </>
      )}
    </div>
  );
};

export default Submitted_Applications;
