import React, { useState, useEffect } from 'react';
import './Shop.css';

const Shop = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [keyword, setKeyword] = useState(''); // Search keyword
  const [resultsPerPage, setResultsPerPage] = useState(10); // Default number of results per page
  const [pageNumber, setPageNumber] = useState(1); // Current page number
  const [searchTriggered, setSearchTriggered] = useState(false); // Track if search has been triggered

  // Function to fetch items from the API
  const fetchItems = async (pageNum, keywordParam, resultsPerPageParam) => {
    setLoading(true);
    const url = `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/Shop?keyword=${encodeURIComponent(keywordParam)}&resultsPerPage=${resultsPerPageParam}&pageNumber=${pageNum}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonResponse = await response.json();
      console.log('Response received:', jsonResponse);

      let responseData = jsonResponse;
      if (jsonResponse.body) {
        responseData = JSON.parse(jsonResponse.body);
      }

      if (responseData.findItemsAdvancedResponse) {
        const items =
          responseData.findItemsAdvancedResponse[0].searchResult[0].item || [];
        setItems(items);
        console.log('Items set:', items);
      } else {
        console.error('Invalid response format', responseData);
        setError('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching items:', error);
      setError('Error fetching items');
    } finally {
      setLoading(false);
    }
  };

  // Use useEffect to trigger fetchItems when pageNumber changes
  useEffect(() => {
    if (searchTriggered) {
      fetchItems(pageNumber, keyword, resultsPerPage); // Fetch items when pageNumber changes
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]); // Dependency on pageNumber only

  // Handle search submission
  const handleSearch = (e) => {
    e.preventDefault();
    setPageNumber(1); // Reset to page 1 on new search
    setSearchTriggered(true); // Mark that search was triggered
    fetchItems(1, keyword, resultsPerPage); // Fetch the search results for page 1
  };

  // Handle next page button click
  const handleNextPage = () => {
    setPageNumber(prevPage => prevPage + 1); // Increment the page number
  };

  // Handle previous page button click
  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(prevPage => prevPage - 1); // Decrement the page number
    }
  };

  // Handle specific page number click
  const handlePageClick = (page) => {
    setPageNumber(page); // Set the page number based on the clicked page
  };

  return (
    <div className="shop-container">
      <h1>Shop</h1>
      <form onSubmit={handleSearch}>
        <input
          type="text"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Search by keyword"
        />
        <input
          type="number"
          value={resultsPerPage}
          onChange={(e) => setResultsPerPage(e.target.value)}
          placeholder="Results per page"
          min="1"
        />
        <button type="submit">Search</button>
      </form>

      {loading ? (
        <p>Loading items...</p>
      ) : error ? (
        <p>{error}</p>
      ) : searchTriggered && items.length === 0 ? (
        <p>No items found.</p>
      ) : (
        <div>
          <div className="item-grid">
            {items.map((item, index) => (
              <div key={index} className="item-card">
                <h3>{item.title[0]}</h3>
                {item.galleryURL && item.galleryURL[0] && (
                  <img src={item.galleryURL[0]} alt={item.title[0]} />
                )}
                <p>
                  Price: {item.sellingStatus[0].currentPrice[0].__value__}{' '}
                  {item.sellingStatus[0].currentPrice[0]['@currencyId']}
                </p>
                <a
                  href={item.viewItemURL[0]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Item
                </a>
              </div>
            ))}
          </div>

          {items.length > 0 && (
            <div className="pagination-buttons">
              {/* Previous Page Button */}
              <button onClick={handlePreviousPage} disabled={pageNumber === 1}>
                Previous Page
              </button>

              {/* Page Numbers (1-9) */}
              {[...Array(9)].map((_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageClick(index + 1)}
                  className={pageNumber === index + 1 ? 'active' : ''}
                >
                  {index + 1}
                </button>
              ))}

              {/* Next Page Button */}
              <button onClick={handleNextPage}>
                Next Page
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Shop;
