import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import './ForgotPassword.css'; // Import the external CSS

async function logPWResetStatus(email) {
  const apiUrl = "https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/passwordResetLogging";

  // Data to send in the request body
  const requestData = {
      httpMethod: "POST",
      email: email,
      changedBy: email,
      reason: "Forgot Password Request"
  };

  try {
      // Making a POST request to API Gateway
      const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
      });

      // Checking if the request was successful
      if (response.ok) {
          const responseData = await response.json();
          console.log('Success:', responseData);
      } else {
          console.error('Error: Request failed with status', response.status);
      }
  } catch (error) {
      console.error('Error: Could not connect to the API Gateway', error);
  }
}
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('requestReset');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const output = await resetPassword({
        username: email,
      });

      if (output.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        setStep('confirmReset');
      }
    } catch (err) {
      console.error('Error requesting password reset:', err);
      setError(err.message || 'Failed to request password reset.');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (newPassword !== confirmNewPassword) {
      setError('Passwords do not match. Please try again.');
      setLoading(false);
      return;
    }

    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: confirmationCode,
        newPassword: newPassword,
      });

      alert('Password successfully reset. Please log in with your new password.');
      logPWResetStatus(email);
      navigate('/login');
    } catch (err) {
      console.error('Error confirming password reset:', err);
      setError(err.message || 'Failed to reset password.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-form-container">
        <h2 className="forgot-password-title">Forgot Password</h2>
        {step === 'requestReset' ? (
          <form onSubmit={handleResetPassword} className="forgot-password-form">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                className="form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email"
              />
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="forgot-password-button" disabled={loading}>
              {loading ? 'Requesting reset...' : 'Reset Password'}
            </button>
          </form>
        ) : (
          <form onSubmit={handleConfirmResetPassword} className="forgot-password-form">
            <div className="form-group">
              <label htmlFor="confirmationCode">Confirmation Code</label>
              <input
                type="text"
                id="confirmationCode"
                className="form-input"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                required
                placeholder="Enter the confirmation code"
              />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                className="form-input"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                placeholder="Enter your new password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmNewPassword">Confirm New Password</label>
              <input
                type="password"
                id="confirmNewPassword"
                className="form-input"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
                placeholder="Confirm your new password"
              />
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="forgot-password-button" disabled={loading}>
              {loading ? 'Confirming reset...' : 'Confirm Reset'}
            </button>
          </form>
        )}
        <div className="forgot-password-footer">
          <button onClick={() => navigate('/login')} className="footer-button">
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
