import React from 'react';
import { useLocation } from 'react-router-dom';
import './Alerts.css';

const Alerts = () => {
  const location = useLocation();
  const [alerts, setAlerts] = React.useState(location.state?.alerts || []);

  // Calculate the count of unread alerts
  const unreadCount = alerts.filter(alert => !alert.read).length;

  const toggleReadStatus = async (alertID, shouldMarkAsRead) => {
    try {
      const response = await fetch(
        'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/users/alert', 
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ alertID, markAsRead: shouldMarkAsRead })
        }
      );
      
      if (response.ok) {
        setAlerts(prevAlerts =>
          prevAlerts.map(alert =>
            alert.alertID === alertID ? { ...alert, read: shouldMarkAsRead } : alert
          )
        );
      } else {
        console.error('Failed to update alert status');
      }
    } catch (error) {
      console.error('Error updating alert status:', error);
    }
  };

  return (
    <div className="alerts-page">
      <h2>Your Alerts</h2>
      <p>{`You have ${unreadCount} unread ${unreadCount === 1 ? 'alert' : 'alerts'}`}</p>
      <div className="alerts-list">
        {alerts.length > 0 ? (
          alerts.map((alert, index) => (
            <div key={index} className={`alert ${alert.read ? 'read' : 'unread'}`}>
              <p><strong>Type:</strong> {alert.alertType}</p>
              <p><strong>Message:</strong> {alert.alertMessage}</p>
              <p><strong>Sponsor:</strong> {alert.sponsorMember || 'N/A'}</p>
              <p><strong>Amount:</strong> {alert.amount}</p>
              <p><strong>Date:</strong> {alert.alertDate}</p>
              <p><strong>Status:</strong> {alert.read ? 'Read' : 'Unread'}</p>
              
              {alert.read ? (
                <button onClick={() => toggleReadStatus(alert.alertID, false)}>Mark as Unread</button>
              ) : (
                <button onClick={() => toggleReadStatus(alert.alertID, true)}>Mark as Read</button>
              )}
            </div>
          ))
        ) : (
          <p>No alerts available</p>
        )}
      </div>
    </div>
  );
};

export default Alerts;
