// Points.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Points.css';

const Points = () => {
  const [bioData, setBioData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [pointHistory, setPointHistory] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [pointChange, setPointChange] = useState('');
  const [reasoning, setReasoning] = useState('');
  const [conversionRate, setConversionRate] = useState('');
  const [editedConversionRate, setEditedConversionRate] = useState({ conversionRate: 0 });
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchPointsData = async () => {
      try {
        const email = location.state?.email || localStorage.getItem('email');

        if (email) {
          localStorage.setItem('email', email);
          // Proceed with fetching data using the email
        } else {
          setError('Email is undefined');
          setLoading(false);
        }

        // Fetch user bio data
        const bioResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`
        );
        if (!bioResponse.ok) {
          const errorBody = await bioResponse.text();
          throw new Error(`Failed to fetch bio data: ${errorBody}`);
        }
        const bioData = await bioResponse.json();
        setBioData(bioData);
        setUserRole(bioData.userRole);

        // Fetch conversion rate for sponsorOrg
        const conversionRateResponse = await fetch(
          `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/pointConversion?sponsorOrg=${encodeURIComponent(
            bioData.sponsorOrg
          )}`
        );
        if (!conversionRateResponse.ok) {
          console.warn('No conversion rate available for this sponsorOrg.');
          setConversionRate(null);
        } else {
          const conversionRateData = await conversionRateResponse.json();
          setConversionRate(conversionRateData);
          setEditedConversionRate(conversionRateData);
        }

        // Fetch users with the same sponsorOrg (for Sponsors and Admins)
        if (bioData.userRole === 'Admin' || bioData.userRole === 'Sponsor') {
          const usersResponse = await fetch(
            `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/users?sponsorOrg=${encodeURIComponent(
              bioData.sponsorOrg
            )}`
          );
          if (!usersResponse.ok) {
            console.warn('No users available for this sponsorOrg.');
            setUserList([]);
          } else {
            const usersData = await usersResponse.json();
            setUserList(usersData);
          }
        }

        // Fetch user's point history (only for Drivers)
        if (bioData.userRole === 'Driver') {
          const historyResponse = await fetch(
            `https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/pointHistory?email=${encodeURIComponent(
              email
            )}`
          );
          if (!historyResponse.ok) {
            console.warn('No point history available.');
            setPointHistory(null);
          } else {
            const pointHistoryData = await historyResponse.json();
            setPointHistory(pointHistoryData);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchPointsData();
  }, []);

  const goToRewardsPenalties = () => {
    navigate('/rewards_penalties');
  };

  const handleUserSelect = (event) => {
    setSelectedUser(event.target.value);
  };

  const handlePointChange = (event) => {
    setPointChange(event.target.value);
  };

  const handleReasoningChange = (event) => {
    setReasoning(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const currentUserEmail = location.state?.email || localStorage.getItem('email');

      const requestData = {
        targetEmail: selectedUser,
        modifierEmail: currentUserEmail,
        points_change: parseInt(pointChange),
        description: reasoning,
      };

      const response = await fetch(
        'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/points',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(`Failed to submit points change: ${errorBody}`);
      }

      const responseData = await response.json();
      console.log('Points change submitted successfully:', responseData);
      alert('Points change submitted successfully!');
      setPointChange('');
      setReasoning('');
      setSelectedUser('');
    } catch (error) {
      console.error('Error submitting points change:', error);
      alert('Error submitting points change. Please try again.');
    }
  };

  const handleConversionRateChange = (event) => {
    const newConversionRate = parseFloat(event.target.value);
    if (!isNaN(newConversionRate)) {
      setEditedConversionRate({ conversionRate: newConversionRate });
    }
  };

  const handleConversionRateSubmit = async () => {
    try {
      const formattedConversionRate = parseFloat(editedConversionRate.conversionRate).toFixed(2);

      const requestBody = {
        sponsorOrg: bioData.sponsorOrg,
        conversionRate: formattedConversionRate,
      };

      const response = await fetch(
        'https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/pointConversion',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update conversion rate');
      }

      setConversionRate(editedConversionRate);
      setIsEditing(false);

      const responseData = await response.json();
      console.log('Conversion rate change submitted successfully:', responseData);
      alert('Conversion rate updated successfully!');
    } catch (error) {
      console.error('Error submitting edited conversion rate:', error);
      alert('Error updating conversion rate. Please try again.');
    }
  };

  if (loading) {
    return <p className="loading">Loading...</p>;
  }

  return (
    <div className="points-container">
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          <h1>Points</h1>

          {/* Point Total - Only for Drivers */}
          {userRole === 'Driver' && (
            <div className="point-total">
              {bioData.points !== null ? (
                <p>
                  <span className="bold-text">Point Total: </span>
                  {bioData.points}
                </p>
              ) : null}
            </div>
          )}

          <div className="conversion-rate">
            {conversionRate ? (
              <>
                <p>
                  <span className="bold-text">Conversion Rate: </span>
                  {isEditing ? (
                    <input
                      type="number"
                      name="conversionRate"
                      className="conversion-input"
                      value={editedConversionRate.conversionRate || ''}
                      onChange={handleConversionRateChange}
                    />
                  ) : (
                    `$${parseFloat(conversionRate.conversionRate).toFixed(2)}`
                  )}
                  {(userRole === 'Admin' || userRole === 'Sponsor') && (
                    <>
                      {!isEditing ? (
                        <button onClick={() => setIsEditing(true)} className="edit-rate-button">
                          Edit
                        </button>
                      ) : (
                        <>
                          <button onClick={handleConversionRateSubmit} className="save-button">
                            Save
                          </button>
                          <button onClick={() => setIsEditing(false)} className="cancel-button">
                            Cancel
                          </button>
                        </>
                      )}
                    </>
                  )}
                </p>
                <p className="italic-text">
                  (1 point is worth ${parseFloat(conversionRate.conversionRate).toFixed(2)} USD)
                </p>
              </>
            ) : (
              <p>No conversion rate available.</p>
            )}
          </div>

          {/* Point History - Only for Drivers */}
          {userRole === 'Driver' && (
            <div className="point-history">
              <h2>Point History</h2>
              {pointHistory && pointHistory.length > 0 ? (
                <table className="history-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Made By</th>
                      <th>Change</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pointHistory.map((history, index) => (
                      <tr key={index}>
                        <td>{new Date(history.transactionDate).toLocaleString()}</td>
                        <td>{history.modifierName}</td>
                        <td>{history.pointsChange} points</td>
                        <td>{history.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No point history available.</p>
              )}
            </div>
          )}

          {/* Change Points - Only for Sponsors and Admins */}
          {(userRole === 'Admin' || userRole === 'Sponsor') && (
            <div className="change-points">
              <h2>Change Points</h2>

              <div className="form-group">
                <label htmlFor="userSelect">Select a user:</label>
                <select
                  id="userSelect"
                  value={selectedUser}
                  onChange={handleUserSelect}
                  className="form-control"
                >
                  <option value="">-- Select User --</option>
                  {userList
                    .filter((user) => user.userRole === 'Driver')
                    .map((user) => (
                      <option key={user.email} value={user.email}>
                        {user.firstName} {user.lastName} ({user.email})
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="pointsChange">Add or remove points:</label>
                <input
                  type="number"
                  id="pointsChange"
                  name="pointsChange"
                  value={pointChange}
                  onChange={handlePointChange}
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label htmlFor="causeOfChange">Cause of change:</label>
                <input
                  type="text"
                  id="causeOfChange"
                  name="causeOfChange"
                  value={reasoning}
                  onChange={handleReasoningChange}
                  className="form-control"
                />
              </div>

              <button onClick={handleSubmit} className="submit-button">
                Submit
              </button>
            </div>
          )}

          {/* Button to navigate to Rewards and Penalties */}
          <button onClick={goToRewardsPenalties} className="rewards-penalties-button">
            Rewards and Penalties
          </button>
        </>
      )}
    </div>
  );
};

export default Points;