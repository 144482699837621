// App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import About from './pages/About/About';
import CreateAccount from './pages/Create Account/CreateAccount';
import Bio from './pages/Bio/Bio';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Signup from './pages/Login/Signup';
import ForgotPassword from './pages/Login/ForgotPassword';
import ConfirmSignup from './pages/Login/ConfirmSignup';
import Goodbye from './pages/Goodbye/Goodbye';
import Layout from './buttons/Layout';
import AuditLogs from './pages/AuditLogs/AuditLogs';
import Points from './pages/Points/Points';
import Rewards_Penalties from './pages/Rewards_Penalties/Rewards_Penalties';
import Applications from './pages/Applications/Applications';
import Submitted_Applications from './pages/Applications/Submitted_Applications';
import Connect from './pages/Connect/Connect';
import PointReports from './pages/AuditLogs/PointReports';
import AuditDashboard from './pages/AuditLogs/AuditDash';
import Alerts from './pages/Alerts/Alerts';
import Shop from './pages/Shop/Shop';
import TimeoutSettings from './pages/TimeoutSettings/TimeoutSettings';
import TimeoutGoodbye from './pages/TimeoutGoodbye/TimeoutGoodbye';
import PWLogs from './pages/AuditLogs/PWLogs';
import UserMgt from './pages/userMgt/userMgt';
function App() {
  return (
    <div>
      <Routes>
        {/* Routes without navbar */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/confirm-signup" element={<ConfirmSignup />} />
        <Route path="/goodbye" element={<Goodbye />} />
        <Route path="/timeout-goodbye" element={<TimeoutGoodbye />} />
        {/* Routes with navbar and Layout */}
        <Route
          path="/dashboard"
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        />
        <Route
          path="/alerts"
          element={
            <Layout>
              <Alerts />
            </Layout>
          }
        />
        <Route
          path="/shop"
          element={
            <Layout>
              <Shop />
            </Layout>
          }
        />
        {/* <Route
          path="/userMgt"
          element={
            <Layout>
              <UserMgt />
            </Layout>
          }
        /> */}
        <Route
          path="/about"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />
        <Route
          path="/create-account"
          element={
            <Layout>
              <CreateAccount />
            </Layout>
          }
        />
        <Route
          path="/bio"
          element={
            <Layout>
              <Bio />
            </Layout>
          }
        />
        <Route
          path="/connect"
          element={
            <Layout>
              <Connect />
            </Layout>
          }
        />
        <Route
          path="/points"
          element={
            <Layout>
              <Points />
            </Layout>
          }
        />
        <Route
          path="/rewards_penalties"
          element={
            <Layout>
              <Rewards_Penalties />
            </Layout>
          }
        />
        <Route
          path="/applications"
          element={
            <Layout>
              <Applications />
            </Layout>
          }
        />
        <Route
          path="/submitted_applications"
          element={
            <Layout>
              <Submitted_Applications />
            </Layout>
          }
        />
        <Route
          path="/audit"
          element={
            <Layout>
              <AuditLogs />
            </Layout>
          }
        />
        <Route
          path="/auditDash"
          element={
            <Layout>
              <AuditDashboard />
            </Layout>
          }
        />
        <Route
          path="/PWLogs"
          element={
            <Layout>
              <PWLogs />
            </Layout>
          }
        />

        <Route
          path="/PointReports"
          element={
            <Layout>
              <PointReports />
            </Layout>
          }
        />
        <Route
          path="/timeout-settings"
          element={
            <Layout>
              <TimeoutSettings />
            </Layout>
          }
        />
      </Routes>
    </div>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
